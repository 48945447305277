<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <GetCommits />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import GetCommits from '@/components/GetCommits'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    GetCommits,
    Footer
  }
}
</script>
