<template>
  <div id="GetCommits">
    <h1>Commits:</h1>
  </div>
</template>

var apiURL = 'https://api.github.com/repos/StevenDumont/StevenDumont/commits?page=1'

var app = new createApp({
  data: function() {
    return {
      branches:["master","dev"],
      currentBranch:"master",
      commits: null
    }
  },

  created: function(){
    this.fetchData();
  },

  watch:{
    currentBranch: "fetchData"
  },

  filters:{
    truncate: function(v) {
      var newline = v.indexOf("\n");
      return newline > 0 ? v.slice(0, newline) : v;
    },

    formatDate: function(v) {
      return v.replace(/T|Z/g, " ");
    },

    methods: {
      fetchData: function(){
        var xhr = new XMLHttpRequest();
        var self = this;
        xhr.open("GET", apiURL + self.currentBranch);
        xhr.onload = function() {
          self.commits = JSON.parse(xhr.responseText);
          console.log(self.commits[0].html_url);
        };
        xhr.send();
      }
    }
  }
})

export default {
  name: 'GetCommits'
}
